import React from 'react';
import { Container } from 'react-bootstrap';

const Landsdale = () => {
  return (
    <Container>
      <h1>Landsdale</h1>
      <h2>Instructors</h2>
      <ul>
        <li>New and modern Dojo with plenty of traditional and new age training equipment</li>
        <li>Affordable training fees, discounts for family groups and yearly payments.</li>
        <li>30 minutes North of Perth CBD.</li>
      </ul>

      <p><strong>Instructor</strong><br />
        <a href="#jim">Sensei Jim Gloriod</a><br />
        <strong>Email:</strong> Jim.gloriod@yahoo.com<br />
        <strong>Tel:</strong> 0408 908 239</p>

      <p><strong>Class Times</strong><br />
        <strong>Tuesday to Thursday:</strong> 6.00pm - 7.30pm, children and adults.</p>

      <p><strong>Prices</strong><br />
            Seniors $50.00/month<br />
            Juniors (U16) $35.00/month</p>

      <p><strong>Location</strong><br />
            Unit 4/ 10 Dillington Pass, Landsdale, WA 6055</p>

      <hr />
      <a href="#top" id="jim">Top</a>

      <h2>Jim Gloriod</h2>
      <p>Jim Gloriod started martial at as a 10 year old at a Perth PCYC doing Judo. He only trained for six months and then moved on to other interests. Jim recommenced his training in 1985 at age 17 with a style of GoJu Ryu. After 18 months he moved on try the style of Bando Banshay. He did this for 6  months. It was in 1987 that Jim started Okinawan GoJu Ryu with Sensei Terry Lyon, WA Chief Instructor of the IOGKF. He knew after a short time, that this was the style he had been looking for.</p>

      <p>Jim spent a year on the East coast working and joined a Kyuokoshin Kai Dojo for that period. He kept in touch with sensei Lyon whilst away and promptly joined the Dojo again upon his return. Jim trained with vigor, several times a week, eventually taking classes for Sensei Lyon on weekends.</p>

      <p>Jim followed the Dojo through the transition from IOGKF to TOGKA, gaining the leadership and guidance offered by Sensei Ravey. Throughout these early years of Jim's journey in karate he entered several kumite/kata tournaments. These were mainly full contact and most of them open to all styles of martial arts. He had a fair amount of success in these comps by winning a number of titles and also representing WA in a five man full contact team that fought against a Victorian team in 1993 at the 'Speed Dome' in Perth. Jim trained in Judo throughout this period to supplement his Goju Ryu training and ground skills.</p>

      <p>Jim was graded to Shodan in 1991 and Nidan in 1993, both at the Mt Lawley Dojo by Sensei Ravey. In 1993 he took over as head instructor of the Craigie Dojo from Sensei Colin Craggs. Craigie Dojo saw many students over the eight years under Jim's leadership, some which have worked through to become Dansha and are still training today. Craigie Dojo closed in 2001 when the the high school that it was part of, was demolished.

He trained with Sensei Ravey on many occasions, both in Qld and WA. Jim took his Sandan grading with Sensei Ravey in 1996, like many others, in the mountains of Kilcoy. Jim moved to Qld early in the year 2001 to pursue a career as a skydiving instructor. He jumped for a living onto the beaches if Qld for two years. During this time he trained with Sensei Ravey on many occasions. He returned to WA in 2003 with his wife to be, Bianca, who was carrying their  first child.</p>

      <p>Over the next 9 years, Jim stayed in touch with karate on a fairly casual level. He focused his life on Bianca and their two sons, gaining a job offshore on an oil rig.</p>

      <p>In 2012, a long time student and great friend of Jim's, Chris Finn, built the Dojo at Landsdale in order to resurrect the old Craigie Dojo and to provide a place continue to train Goju Ryu. Landsdale Dojo has some of the old members from Craigie as well as some new students just beginning karate. </p>

      <p>Jim now works for an engineering firm, facilitating inductions for the Iron Ore and offshore sector of industry. He is now the Head Instructor of the Landsdale Dojo, and training regularly once more.</p>

      <p>The Landsdale Dojo is very impressive indeed!</p>
    </Container>

  )
}

export default Landsdale;
